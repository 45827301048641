import { graphql } from "gatsby";
import * as React from "react";
import DefaultPage from "../templates/default";

export default function fourOhFourPage(props) {
  return (
    <DefaultPage {...props} />
  );
}

export const pageQuery = graphql`
  query FourOhFour {
    wpPage(id: {eq: "cG9zdDoxMDA="}) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
